import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import MetricTrend from "../metrics/metric-trend";
import { useApiTenant } from "../../../api/api-tenant";
import { getTrendLabels, getLabels } from "../../../helpers/metrics";
export default function DashboardMetrics() {
    const { t } = useTranslation();
    const api = useApiTenant();
    const [ticketsTrendLabels, setTicketsTrendLabels] = useState([]);
    const [ticketsTrendValues, setTicketsTrendValues] = useState([]);
    const [ticketsTrendCachedAt, setTicketsTrendCachedAt] = useState("");
    const [ticketsTrendRange, setTicketsTrendRange] = useState("this_week");
    const [ticketsTrendLoading, setTicketsTrendLoading] = useState(false);
    const [ticketsTrendError, setTicketsTrendError] = useState(false);
    const [contactsTrendLabels, setContactsTrendLabels] = useState([]);
    const [contactsTrendValues, setContactsTrendValues] = useState([]);
    const [contactsTrendCachedAt, setContactsTrendCachedAt] = useState("");
    const [contactsTrendRange, setContactsTrendRange] = useState("this_month");
    const [contactsTrendLoading, setContactsTrendLoading] = useState(true);
    const [contactsTrendError, setContactsTrendError] = useState(true);
    const [usersTrendLabels, setUsersTrendLabels] = useState([]);
    const [usersTrendValues, setUsersTrendValues] = useState([]);
    const [usersTrendCachedAt, setUsersTrendCachedAt] = useState("");
    const [usersTrendRange, setUsersTrendRange] = useState("this_year");
    const [usersTrendLoading, setUsersTrendLoading] = useState(true);
    const [usersTrendError, setUsersTrendError] = useState(false);
    const [ticketsMetricsRefresh, setTicketsMetricsRefresh] = useState(false);
    const [contactsMetricsRefresh, setContactsMetricsRefresh] = useState(false);
    const [usersMetricsRefresh, setUsersMetricsRefresh] = useState(false);
    const getTicketsTrend = invalidateCache => {
        setTicketsTrendLoading(true);
        if (ticketsTrendError)
            setTicketsTrendError(false);
        let ticketsLabels = getTrendLabels(ticketsTrendRange);
        let labels = getLabels(ticketsLabels, ticketsTrendRange);
        setTicketsTrendLabels(labels);
        api.GET_metrics("trend", "tickets", ticketsTrendRange, undefined, undefined, "is_draft", "0", undefined, invalidateCache ? 1 : undefined)
            .then(response => {
            let values = [];
            ticketsLabels.map(label => {
                if (response.data.data.find(a => a.date === label)) {
                    values.push(response.data.data.find(a => a.date === label).count);
                }
                else {
                    values.push(0);
                }
            });
            setTicketsTrendCachedAt(response.data.cached_at);
            setTicketsTrendValues(values);
            setTicketsTrendLoading(false);
        })
            .catch(error => {
            setTicketsTrendError(true);
            if (!api.isCancel(error)) {
                console.error(error);
                setTicketsTrendLoading(false);
            }
        });
        return () => api.cancel();
    };
    const getUsersTrend = invalidateCache => {
        setUsersTrendLoading(true);
        if (usersTrendError)
            setUsersTrendError(false);
        let usersLabels = getTrendLabels(usersTrendRange);
        let labels = getLabels(usersLabels, usersTrendRange);
        setUsersTrendLabels(labels);
        api.GET_metrics("trend", "tenant_users", usersTrendRange, undefined, undefined, undefined, undefined, undefined, invalidateCache ? 1 : undefined)
            .then(response => {
            let values = [];
            usersLabels.map(label => {
                if (response.data.data.find(a => a.date === label)) {
                    values.push(response.data.data.find(a => a.date === label).count);
                }
                else {
                    values.push(0);
                }
            });
            setUsersTrendCachedAt(response.data.cached_at);
            setUsersTrendValues(values);
            setUsersTrendLoading(false);
        })
            .catch(error => {
            setUsersTrendError(true);
            if (!api.isCancel(error)) {
                console.error(error);
                setUsersTrendLoading(false);
            }
        });
        return () => api.cancel();
    };
    const getContactsTrend = invalidateCache => {
        setContactsTrendLoading(true);
        if (contactsTrendError)
            setContactsTrendError(false);
        let contactsLabels = getTrendLabels(contactsTrendRange);
        let labels = getLabels(contactsLabels, contactsTrendRange);
        setContactsTrendLabels(labels);
        api.GET_metrics("trend", "contacts", contactsTrendRange, undefined, undefined, undefined, undefined, undefined, invalidateCache ? 1 : undefined)
            .then(response => {
            let values = [];
            contactsLabels.map(label => {
                if (response.data.data.find(a => a.date === label)) {
                    values.push(response.data.data.find(a => a.date === label).count);
                }
                else {
                    values.push(0);
                }
            });
            setContactsTrendCachedAt(response.data.cached_at);
            setContactsTrendValues(values);
            setContactsTrendLoading(false);
        })
            .catch(error => {
            setContactsTrendError(true);
            if (!api.isCancel(error)) {
                console.error(error);
                setContactsTrendLoading(false);
            }
        });
        return () => api.cancel();
    };
    useEffect(() => {
        if (ticketsMetricsRefresh) {
            getTicketsTrend(true);
            setTicketsMetricsRefresh(false);
        }
        if (usersMetricsRefresh) {
            getUsersTrend(true);
            setUsersMetricsRefresh(false);
        }
        if (contactsMetricsRefresh) {
            getContactsTrend(true);
            setContactsMetricsRefresh(false);
        }
    }, [ticketsMetricsRefresh, usersMetricsRefresh, contactsMetricsRefresh]);
    useEffect(() => {
        getTicketsTrend(undefined);
    }, [ticketsTrendRange]);
    useEffect(() => {
        getUsersTrend(undefined);
    }, [usersTrendRange]);
    useEffect(() => {
        getContactsTrend(undefined);
    }, [contactsTrendRange]);
    return (_jsx(Box, Object.assign({ sx: {
            marginBottom: 4,
        } }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 4 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, xl: 4 }, { children: _jsx(MetricTrend, { title: t("tickets.metrics.tickets-trend"), label: t("tickets.tickets"), labels: ticketsTrendLabels, values: ticketsTrendValues, range: ticketsTrendRange, setRange: setTicketsTrendRange, isLoading: ticketsTrendLoading, setIsLoading: setTicketsTrendLoading, cachedAt: ticketsTrendCachedAt, refresh: ticketsMetricsRefresh, setRefresh: setTicketsMetricsRefresh, isError: ticketsTrendError }) })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, xl: 4 }, { children: _jsx(MetricTrend, { title: t("contacts.metrics.contacts-trend"), label: t("contacts.contacts"), labels: contactsTrendLabels, values: contactsTrendValues, range: contactsTrendRange, setRange: setContactsTrendRange, isLoading: contactsTrendLoading, setIsLoading: setContactsTrendLoading, cachedAt: contactsTrendCachedAt, refresh: contactsMetricsRefresh, setRefresh: setContactsMetricsRefresh, isError: contactsTrendError }) })), _jsx(Hidden, Object.assign({ xlDown: true }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, md: 6, xl: 4 }, { children: _jsx(MetricTrend, { title: t("users.metrics.users-trend"), label: t("users.users"), labels: usersTrendLabels, values: usersTrendValues, range: usersTrendRange, setRange: setUsersTrendRange, isLoading: usersTrendLoading, setIsLoading: setUsersTrendLoading, cachedAt: usersTrendCachedAt, refresh: usersMetricsRefresh, setRefresh: setUsersMetricsRefresh, isError: usersTrendError }) })) }))] })) })));
}
